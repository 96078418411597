<style>
  @import "/css/story.css";
</style>

<template>
    <div class="contact">
        <ContactDetail></ContactDetail>
        <Separation></Separation>
        <Partners></Partners>
        <Footer></Footer>
    </div>
</template>

<script>

import Separation from '@/components/Home/Separation.vue'
import ContactDetail from '@/components/Contact/ContactDetail.vue'
import Partners from '@/components/Contact/Partners.vue'
import Footer from '@/components/Home/Footer.vue'

export default {

    name: 'Contact',

    components: {
        Separation,
        ContactDetail,
        Partners,
        Footer
    }
}

</script>
