<template>
    <section id="partner" class="main style1 special">
        <div class="container">
            <h2>Partenaires</h2>
            <a href="https://pacimind.ch/" target="_blank">
                <img src="images/partner_logo/pacimind.png" class="logo"/>
            </a>
            <a href="https://maspiruline.ch/" target="_blank">
                <img src="images/partner_logo/maspiruline.jpg" class="logo ma-spiruline"/>
            </a>
            <a href="https://www.rmalchimie.ch/" target="_blank">
                <img src="images/partner_logo/rmalchimie_v2.jpg" class="logo"/>
            </a>
            <a href="https://statera-conseil.ch/" target="_blank">
                <img src="images/partner_logo/statera.jpeg" class="logo"/>
            </a>
        </div>
    </section>
</template>
