<template>

    <section id="contact_form" class="main style1 special">
        <div class="container">
            <div class="row gtr-150">

                <div class="col-6 col-12-medium">

<!--
                    <div class="row gtr-uniform gtr-50">
                        <div class="col-6 col-12-medium">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10942.56998224393!2d6.5743069!3d46.7127877!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1e3eb342c3400a1!2sAnkyra%20Nutrition!5e0!3m2!1sfr!2sch!4v1625521595565!5m2!1sfr!2sch" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                        </div>
                        <div class="col-6 col-12-medium">
                            <img src="images/contact/shield.jpg" class="contact"/>
                        </div>
                    </div>
-->

                    <div class="row gtr-uniform gtr-50">
                        <div class="col-12">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10942.56998224393!2d6.5743069!3d46.7127877!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1e3eb342c3400a1!2sAnkyra%20Nutrition!5e0!3m2!1sfr!2sch!4v1625521595565!5m2!1sfr!2sch" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                        </div>
                    </div>


                    <div class="row gtr-uniform gtr-50">
                        <div class="col-6">
                            <p>Elodie Auberson<br>
                              Place du Collège 7<br>
                              1375 Penthéréaz<br><br>
                            </p>
                        </div>
                        <div class="col-6">
                            <p>
                                +41 79 833 57 15<br>
                                elodie@ankyra.ch
                            </p>
                        </div>
                    </div>

                    <div class="row gtr-uniform gtr-50">
                        <div class="col-6">
                            <a href="https://asca.ch/therapeutes-asca/detail-du-therapeute/Elodie-Auberson_624160/" target="_blank">
                                <img src="images/logoASCA.png" class="logo"/>
                            </a>
                        </div>
                        <div class="col-6">
                            <a href="https://emr.ch/therapeute/elodie.auberson/" target="_blank">
                                <img src="images/logoRME.png" class="logo"/>
                            </a>
                        </div>
                    </div>

                </div>

                <div class="col-6 col-12-medium">
                    <div class="form-fields" v-if="!formSent">
                        <div class="row gtr-uniform gtr-50">
                            <div class="col-12">
                                <input type="text" v-model="name" name="name" id="name" placeholder="Nom*" />
                            </div>
                            <div class="col-12">
                                <input type="email" v-model="email" name="email" id="email" placeholder="Email*" />
                            </div>
                            <div class="col-12">
                                <input type="text" v-model="title" name="title" id="title" placeholder="Sujet" />
                            </div>
                            <div class="col-12">
                                <textarea name="message" v-model="message" id="message" placeholder="Message*" rows="6"></textarea>
                            </div>
                            <div class="col-12">
                                <ul class="actions">
                                    <li><input type="submit" value="Envoyer" class="primary" v-on:click="sendContactEmail()" /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="form-thanks" v-if="formSent">
                        <p>Merci pour votre message</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>

export default {
    name: 'ContactDetail',

    data() {
        return {
            name: "",
            email: "",
            title: "",
            message: "",
            formSent: false
        }
    },

    methods: {
        sendContactEmail() {
            if (this.name && this.email && this.message) {
                let formData = new FormData();
                formData.set('name', this.name);
                formData.set('email', this.email);
                formData.set('title', this.title);
                formData.set('message', this.message);

                this.formSent = true

                this.$http.post('https://api.ankyra.ch/contact-email', formData,
                // this.$http.post('http://localhost:8000/contact-email', formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then((response) => {
                    console.log(response)
                });
            }
        }
    }
}

</script>
